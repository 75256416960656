import React, { useEffect, useState } from "react";
import {
  ActiveStep,
  ArrowWrap,
  ControlsWrap,
  FlowCont,
  FlowControls,
  FlowImgWrap,
  SideSlidesWrap,
  SlideImgWrap,
  SlidesFlowWrap,
  SlidesHead,
  StepsWrap,
  TotelStep,
} from "../presentation-styled";
import slideIcon from "../Images/sildes.svg";
import GoBack from "../Images/goBack.svg";
import leftArr from "../Images/leftArr.svg";
const SideSlides = ({
  pdfDocument,
  selectedPage,
  setSelectedPage,
  setHidePanel,
}) => {
  const [thumbnails, setThumbnails] = useState([]);
  useEffect(() => {
    const renderThumbnails = async () => {
      const thumbnailList = [];
      for (
        let pageNumber = 1;
        pageNumber <= pdfDocument.numPages;
        pageNumber++
      ) {
        const page = await pdfDocument.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 0.5 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport,
        };

        await page.render(renderContext).promise;

        thumbnailList.push({ pageNumber, src: canvas.toDataURL() });
      }
      setThumbnails(thumbnailList);
    };

    renderThumbnails();
  }, [pdfDocument]);
  return (
    <SideSlidesWrap>
      <SlidesHead>
        <SlideImgWrap
          title={"Hide Side Panel"}
          onClick={() => setHidePanel((prev) => !prev)}
        >
           <img src={GoBack} title="Go Back" onClick={()=>{
        window.location.reload();
      }}/>
          <img src={slideIcon} alt="Toggle Side Panel" />
        </SlideImgWrap>
      </SlidesHead>
      <SlidesFlowWrap>
        <FlowCont>
          {thumbnails.length > 0 ? (
            thumbnails.map(({ pageNumber, src }) => (
              <FlowImgWrap
                key={pageNumber}
                onClick={() => setSelectedPage(pageNumber)}
              >
                <div>{pageNumber}</div>
                <img
                  src={src}
                  alt={`Page ${pageNumber}`}
                  style={{
                    border:
                      selectedPage === pageNumber ? "1px solid blue" : "none",
                  }}
                />
              </FlowImgWrap>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </FlowCont>
        <FlowControls>
          <ControlsWrap>
            <StepsWrap>
              <ActiveStep>{selectedPage}</ActiveStep>
              <div>of</div>
              <TotelStep>{pdfDocument?.numPages}</TotelStep>
            </StepsWrap>
            <ArrowWrap>
              <img
                src={leftArr}
                onClick={() => {
                  if (selectedPage > 1) {
                    setSelectedPage(selectedPage - 1);
                  }
                }}
              />
              <img
                src={leftArr}
                className="right-arr"
                onClick={() => {
                  if (selectedPage < pdfDocument?.numPages) {
                    setSelectedPage(selectedPage + 1);
                  }
                }}
              />
            </ArrowWrap>
          </ControlsWrap>
        </FlowControls>
      </SlidesFlowWrap>
    </SideSlidesWrap>
  );
};

export default SideSlides;
