import React from 'react';
import { SketchPicker } from 'react-color';
import { CancelBtn, ColorSaveBtn, SaveCancelCont } from '../presentation-styled';

const ColorPicker = (props) => {
  const { handleColorChange,
    saveFn,
    cancelFn,
    color,
    design } = props;
  return (
    <>
      {" "}
      <SketchPicker
        color={color}
        onChange={(e) => {
          if (!design) {
            handleColorChange(e)
          } else {

            handleColorChange(e, design)
          }
        }} // Handles the color change
      />


      <SaveCancelCont design={design}>
        <CancelBtn
          onClick={(e) => {
            e.stopPropagation();
            if (!design) {
              cancelFn()
            } else {
              cancelFn()
            }
          }}
        >
          Cancel
        </CancelBtn>
        <ColorSaveBtn
          onClick={(e) => {
            e.stopPropagation();
            if (!design) {
              saveFn();
            } else {
              saveFn(design)
            }
          }}
        >
          Save
        </ColorSaveBtn>

      </SaveCancelCont>

    </>
  );
};

export default ColorPicker;