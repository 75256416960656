import React from "react";
import styled from "styled-components";
import { KebabbWrapper } from "../../Dashboard/CaptureDemo/components/IssueBox/issueBox-styled";
import Close from '../Images/Close.svg'
import { usePresentation } from "../context/presentationContext";
// Apply 'Inter' font family globally

const Container = styled.div`
  padding: 20px;
  background-color: #ffffff;
  font-family: "Inter", sans-serif;
  text-align: left;
  border-radius: 4px;
  position: relative;
  overflow-y: auto;
  height: 680px;
`;

const Heading = styled.div`
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 600;
`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const SubHeading = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Text = styled.div`
  font-size: 14px;
  color: #555;
  line-height: 1.5;
  font-weight: 500;
  margin-left: 3px;
`;

const FeedbackForm = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
`;
const CloseImg = styled.img`
position: absolute;
top: 20px;
right: 20px;
cursor: pointer;
`


function RecordingTips() {
  const {presState,presDispatch} = usePresentation();
  const {tipsPopup,feedbackPopup} = presState
  return (
    <KebabbWrapper onClick={(e) => e.stopPropagation()}>
      <Container>
        <CloseImg src={Close} onClick={()=>{
           presDispatch({ type: "HANDLE_TIPS_POPUP", data: "" })
        }}/>
        <Heading>🎥 Tips for Improved Recording 🎬</Heading>

        <Section>
          <SubHeading>🔧 Check Settings</SubHeading>
          <Text>
            Ensure your settings, except the microphone, are configured before
            starting the recording.
          </Text>
        </Section>
        <Section>
        <SubHeading>📷 Connect Devices</SubHeading>
        <Text>Make sure your camera and microphone are connected before you begin.</Text>
      </Section>

      <Section>
        <SubHeading>📱 Screen and Presentation Alignment</SubHeading>
        <Text>If the presentation on your screen isn’t perfectly aligned, don’t worry. The output video will still display correctly.</Text>
      </Section>

      <Section>
        <SubHeading>🖥️ Main Slide Recording</SubHeading>
        <Text>Only the main slide displayed on your screen will be recorded; all other parts of the screen will be ignored.</Text>
      </Section>

      <Section>
        <SubHeading>⏩ Navigation</SubHeading>
        <Text>Use the left and right arrow keys to move between slides.</Text>
      </Section>

      <Section>
        <SubHeading>⏸️ Pause and Resume</SubHeading>
        <Text>Use the Pause and Resume buttons to temporarily stop and restart the recording without canceling it.</Text>
      </Section>

      <Section>
        <SubHeading>❌ Cancel Option</SubHeading>
        <Text>You can use the Cancel button to stop and discard the recording at any time.</Text>
      </Section>

      <Section>
        <SubHeading>✍️ Slide Content Writing</SubHeading>
        <Text>Use the prompt feature to write your content for each slide separately.</Text>
      </Section>

      <Section>
        <SubHeading>⏳ Processing Video</SubHeading>
        <Text>After stopping the recording, do not close the processing window until your video has fully loaded.</Text>
      </Section>

        <FeedbackForm>
          <Text>
            Please share your valuable suggestions and feedback for better
            improvement.
          </Text>
        </FeedbackForm>
      </Container>
    </KebabbWrapper>
  );
}

export default RecordingTips;
