import React, { useEffect, useRef, useState } from "react";
import {
  CancelRecording,
  CounterWrap,
  FoundIssueBtn,
  LayoutItemWrap,
  LayoutMenuHeadWrap,
  LayoutMenuWrap,
  PauseResumeBtn,
  RecButtonsWrap,
  SettingsItem,
  SlideBoadyWrap,
  SlideImgWrap,
  SlideMainWrap,
  SlideModeSettingsCont,
  SlideModeSettingsWrap,
  SlideNameWrap,
  SlideRecOption,
  SlidesMain,
  SlidesMainCont,
  StartRecBtn,
  StartRecBtnWrap,
} from "../presentation-styled";
import Rec from "../Images/rec.svg";
import stopIcon from "../Images/stop.svg";
import pauseIcon from "../Images/pause.svg";
import resumeIcon from "../Images/resume.svg";
import GoBack from "../Images/goBack.svg";
import Camera from "../Images/cam.svg";
import CameraHov from "../Images/camerHov.svg";
import Mic from "../Images/mic.svg";
import MicHov from "../Images/micHov.svg";
import Layout from "../Images/layout.svg";
import LayoutHov from "../Images/layoutHov.svg";
import Background from "../Images/background.svg";
import BackgroundHov from "../Images/backgroundHov.svg";
import Prompt from "../Images/prompt.svg";
import PromptHov from "../Images/promptHov.svg";
import Close from "../Images/Close.svg";
import lay1 from "../Images/lay1.svg";
import lay2 from "../Images/lay2.svg";
import lay1Ac from "../Images/lay1Ac.svg";
import lay2Ac from "../Images/lay2Ac.svg";
import record from "../Images/record.svg";
import Bug from "../Images/bug.png";
import Tip from "../Images/tip.png";
import Default from "../../Dashboard/BrandKit/Images/defaultBg.svg";
import { formatDuration } from "../../../utils/utils";
import ColorPicker from "./colorPicker";
import * as pdfjs from "pdfjs-dist/webpack";
import PromptBox from "./promptBox";
import toast from "react-hot-toast";
import VidedoPresentationPreview from "./videdoPresentationPreview";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../../../context/auth";
import slideIcon from "../Images/sildes.svg";
import RenameCompt from "./renameCompt";
import { usePresentation } from "../context/presentationContext";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const SlideBoady = ({
  pdfDocument,
  selectedPage,
  fileName,
  hidepanel,
  setHidePanel,
  setFileName,

}) => {
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [previewSec, setPreviewSec] = useState(false);
  const [blobItem, setBlobItem] = useState();
  const [layoutOpen, setLayoutOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [hoverEle, setHoverEle] = useState();
  const [time, setTime] = useState(0);
  const [colorPick, setColorPick] = useState({ open: false, picked: "" });
  const [prompt, setPrompt] = useState(false);
  const [hideCam, setHideCam] = useState(" ");
  const [mic, setMic] = useState("");
  const mediaRecorderRef = useRef(null);
  const animationRef = useRef(null);
  const audioStreamRef = useRef(null);
  const [propmptWithSilde, setPromptwithSlide] = useState([]);
  const cameraStreamRef = useRef(null);
  const { presState, presDispatch } = usePresentation()
  const { feedbackPopup, tipsPopup } = presState
  const dummyRef = useRef(null)

  const [clipShape, setClipShape] = useState(" ");
  useEffect(() => {
    setPromptwithSlide(
      Array.from({ length: pdfDocument?.numPages }, (_, index) => ({
        id: index + 1,
        text: ``,
      }))
    );
  }, []);
  let cameraStream = null;
  let audioStream = null;
  const startRecording = async () => {

    const canvas = canvasRef.current;

    const context = canvas.getContext("2d");
    if (!canvas) {
      console.error("Canvas element is not available.");
      return;
    }
    // Capture canvas stream
    const canvasStream = canvas.captureStream(60); // 60 FPS for high quality
    let combinedStream;

    try {
      // Attempt to capture audio stream
      audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      combinedStream = new MediaStream([
        ...canvasStream.getVideoTracks(),
        ...audioStream.getAudioTracks(),
      ]);
      audioStreamRef.current = audioStream;
      setMic("enable");
    } catch (error) {
      console.warn("Audio capture failed or not available:", error);
      setMic("not");
      // If no audio is available, combinedStream will only have video tracks
    }
    // Check if at least video tracks exist in combinedStream

    let mediaRecorder;
    // Initialize MediaRecorder with the combined stream
    if (hideCam === " " || hideCam === "enable") {
      mediaRecorder = new MediaRecorder(
        audioStream ? combinedStream : canvasStream,
        {
          mimeType: "video/mp4",
        }
      );
    } else {
      mediaRecorder = new MediaRecorder(
        audioStream ? combinedStream : canvasStream,
        {
          mimeType: "video/webm",
        }
      );
    }
    const chunks = [];

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(chunks, { type: "video/webm" });
      const url = URL.createObjectURL(blob);
      setBlobItem(blob);
      cameraStream = null;
      audioStream = null;
      // Stop all streams
      if (cameraStream) {
        cameraStream.getTracks().forEach((track) => track.stop());
      }
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }

      // Trigger download

      URL.revokeObjectURL(url);
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
  };

  const checkCamera = async () => {
    try {
      // Capture video feed from camera
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      return true;
    } catch (e) {
      toast.error("No Camera available");
      return false;
    }
  };
  const auth = useAuth();

  let renderAbortController = null; // Track ongoing render operations

  const renderSelectedPage = async () => {
    if (!pdfDocument || !selectedPage) return;
 
    // Cancel any ongoing rendering
    if (renderAbortController) {
      renderAbortController.abort();
    }
    renderAbortController = new AbortController();
    const { signal } = renderAbortController;
 
    const canvas = canvasRef.current;
    const container = canvas.parentNode; // Reference the `.can` container
    const containerWidth = container.clientWidth; // Get container width
    const containerHeight = container.clientHeight; // Get container height
 
    const page = await pdfDocument.getPage(selectedPage);
    const viewport = page.getViewport({ scale: 1 }); // Start with a scale of 1
 
    // Calculate scale to fit the canvas within the container
    const scale = Math.min(
      containerWidth / viewport.width,
      containerHeight / viewport.height
    );
console.log(scale)
console.log(page)
    const adjustedViewport = page.getViewport({ scale }); // Adjusted viewport
    canvas.width = Math.ceil(adjustedViewport.width) ;
    canvas.height = Math.ceil(adjustedViewport.height) ;
    const context = canvas.getContext("2d");  
    // Clear the canvas before rendering
    context.clearRect(0, 0, canvas.width, canvas.height);
    const adjustedViewport1 = page.getViewport({ scale:scale-0.1 }); // Adjusted viewport
 
    // Apply background gradient
    const gradient = context.createLinearGradient(
      0,
      0,
      canvas.width,
      canvas.height
    );
    gradient.addColorStop(0, "#ff7eb3"); // Start color
    gradient.addColorStop(1, "#ff758c"); // End color
    context.fillStyle =
      colorPick?.picked?.length > 0 ? colorPick?.picked : gradient;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.translate(30,20)
    // context.translate(padding*2,padding)
    const renderContext = {
      canvasContext: context,
      viewport: adjustedViewport1, // Use the adjusted viewport
      background: colorPick?.picked?.length > 0 ? colorPick?.picked : gradient,
    };
 
    try {
      // Render the page and handle cancellation
      await page.render(renderContext).promise;
 
      // Clear the abort controller after successful rendering
      if (!signal.aborted) {
        renderAbortController = null;
      }
    } catch (error) {
      if (signal.aborted) {
        console.log("Rendering aborted.");
      } else {
        console.error("Error rendering page:", error);
      }
    }
  };

 

  const stopRecording = async () => {
    setIsRecording(false);
    setPreviewSec(true);
    setTime(0);
    setMic("");
    setIsPaused(false);
    mediaRecorderRef.current.stop();
    mixpanel.track("Presentation recorded", {
      name: auth?.authState?.user?.username,
      email: auth?.authState?.user?.email,
      count: auth?.authState?.user?.videoCount,
    });
    // drawImage(imgSrc)
  };
  let interval;
  useEffect(() => {
    if (isRecording && canvasRef?.current && !isPaused) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1); // Increment the timer every second
        if (canvasRef.current) {
          const canvas = canvasRef.current;
          const context = canvas.getContext("2d");
          context.fillStyle = "transparent";
          context.fillRect(10, 10, 100, 100);
        }
      }, 1000);
      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [isRecording, isPaused]);

  const cancelFn = () => {
    setColorPick({ ...colorPick, open: false });
  };
  const saveFn = () => {
    setColorPick({ ...colorPick, open: false });
  };
  const handleColorChange = (newColor) => {
    setColorPick({ ...colorPick, picked: newColor?.hex });
  };

  useEffect(() => {
    if (canvasRef?.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.fillStyle = "transparent";
      context.fillRect(10, 10, 100, 100);

      renderSelectedPage();
      if (animationRef?.current) {
        cancelAnimationFrame(animationRef.current);
        setTimeout(() => {
          draw();
        }, 100);
      }
    }
    // drawImage();
  }, [pdfDocument, selectedPage]);
  const draw = async () => {
    if (canvasRef?.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const video = videoRef.current;
      const container = canvas.parentNode; // Reference the `.can` container
      const containerWidth = container.clientWidth; // Get container width
      const containerHeight = container.clientHeight; // Get container height
      const shapeSize = 140;
      const radius = shapeSize / 2;

      // Common coordinates
      const shapeX = radius; // X-coordinate for both shapes
      const shapeY = canvas.height - (radius+55) ; // Y-coordinate for both shapes

      // Enable image smoothing for better video quality
      ctx.imageSmoothingEnabled = true;

      // Save the current state before clipping
      ctx.save();

      if (clipShape === "circle" || clipShape === " ") {
        // Define a circular clipping region
        ctx.beginPath();
        ctx.arc(shapeX, shapeY, radius, 0, Math.PI * 2);
        ctx.clip();
      } else if (clipShape === "rectangle") {
        // Define a rectangular clipping region
        ctx.beginPath();
        ctx.rect(shapeX - radius, shapeY - radius, shapeSize, shapeSize);
        ctx.clip();
      }

      // Draw the video feed within the clipping region
      ctx.drawImage(
        video,
        shapeX - radius,
        shapeY - radius,
        shapeSize,
        shapeSize
      );

      // Restore the state after clipping
      ctx.restore();

      // Add the border
      // if (clipShape === "circle" || clipShape === " ") {
      //   ctx.beginPath();
      //   ctx.arc(shapeX, shapeY, radius, 0, Math.PI * 2);
      //   ctx.lineWidth = 1; // Border width
      //   ctx.strokeStyle = "black"; // Border color
      //   ctx.stroke();
      // } else if (clipShape === "rectangle") {
      //   ctx.beginPath();
      //   ctx.rect(shapeX - radius, shapeY - radius, shapeSize, shapeSize);
      //   ctx.lineWidth = 1; // Border width
      //   ctx.strokeStyle = "black"; // Border color
      //   ctx.stroke();
      // }

      // Request the next frame
      animationRef.current = requestAnimationFrame(() => draw());
    }
  };
  useEffect(() => {
    if (cameraStreamRef) {
    }
    return () => {
      cameraStreamRef?.current?.getTracks().forEach((track) => track.stop());
    };
  }, [cameraStreamRef]);
  useEffect(() => {
    if (hideCam === "disable" && animationRef.current) {
      const canvas = canvasRef.current;

      if (canvasRef.current && animationRef.current) {
        const ctx = canvas.getContext("2d");
        // Clear the entire canvas

        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the full canvas
        cancelAnimationFrame(animationRef.current);
        renderSelectedPage();
      }
    } else if (hideCam === "enable" || hideCam === " ") {
      checkAndRecCam();
    }
  }, [hideCam]);
  const checkAndRecCam = async () => {
    let cam = await checkCamera();
    if (cam) {
      try {
        const video = videoRef.current;
        // Attempt to capture video feed from camera
        cameraStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: false,
        });
        cameraStreamRef.current = cameraStream;
        // Set video source and start playback
        if (video) {
          video.srcObject = cameraStream;
          video.play();
        }

        draw();
        // Start the drawing loop to overlay the video
      } catch (error) {
        console.warn("Camera capture failed or not available:", error);
      }
    } else {
      setHideCam("disable");
    }
  };
  useEffect(() => {
    if (
      (clipShape === "circle" || clipShape === "rectangle") &&
      canvasRef.current &&
      animationRef.current
    ) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      // Clear the entire canvas

      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the full canvas
      cancelAnimationFrame(animationRef.current);
      renderSelectedPage();
      setTimeout(() => {
        draw();
      }, 100);
    }
  }, [clipShape]);

  const handleMic = () => {
    if (mic === "enable") {
      setMic("disable");
      audioStreamRef.current.getAudioTracks()[0].enabled = false;
    } else if (mic === "disable") {
      setMic("enable");
      audioStreamRef.current.getAudioTracks()[0].enabled = true;
    }
  };
  useEffect(() => {
    if (colorPick?.picked?.length > 0) {
      renderSelectedPage();
    }
  }, [colorPick]);
  const pauseResumeRecording = () => {
    if (mediaRecorderRef?.current && !isPaused) {
      mediaRecorderRef?.current.pause();
      setIsPaused(true);
    } else if (mediaRecorderRef?.current && isPaused) {
      mediaRecorderRef?.current.resume();
      setIsPaused(false);
    }
  };
  const cancelRecording = () => {
    if (mediaRecorderRef) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      setTime(0);
      setMic("");
      mediaRecorderRef.current = null;
    }
  };
  return (
    <SlideBoadyWrap hidepanel={hidepanel}>
      {isRecording && hidepanel && (
        <SlideRecOption>
          <SlideImgWrap
            title={"Hide Side Panel"}
            onClick={() => setHidePanel((prev) => !prev)}
          >
            <img src={slideIcon} alt="Toggle Side Panel" />
          </SlideImgWrap>
        </SlideRecOption>
      )}
      {!isRecording && (
        <SlideNameWrap hidepanel={hidepanel}>
          {hidepanel && (
            <SlideImgWrap>
              <img
                src={GoBack}
                title="Go Back"
                onClick={() => {
                  window.location.reload();
                }}
              />
              <img
                src={slideIcon}
                alt="Toggle Side Panel"
                title={"Show Side Panel"}
                onClick={() => setHidePanel((prev) => !prev)}
              />
            </SlideImgWrap>
          )}
          <RenameCompt fileName={fileName} setFileName={setFileName} />

          {hidepanel && <div></div>}
          {/* <FoundIssueBtn
            className="tip-rec"
            onClick={() => {
              presDispatch({ type: "HANDLE_TIPS_POPUP", data: "record" })
              mixpanel.track("Tips clicked", {
                name: auth?.authState?.user?.username,
                email: auth?.authState?.user?.email,
                from: 'record'
              });
            }}
          >
            <img draggable={false} src={Tip} /> Tips for Recording
          </FoundIssueBtn>
          <FoundIssueBtn onClick={() => {
            presDispatch({ type: "HANDLE_FEEDBACK_POPUP", data: "record" })
          }}>
            <img draggable={false} src={Bug} /> Feedback/Issue
          </FoundIssueBtn> */}
        </SlideNameWrap>
      )}
      <SlideMainWrap>
        <SlidesMainCont ref={dummyRef}>
          <div className="can">
            <SlidesMain ref={canvasRef}></SlidesMain>
          </div>

          <video ref={videoRef} style={{ display: "none" }} />
          <StartRecBtnWrap isRecording={isRecording} hidepanel={hidepanel}>
            {isRecording && (
              <CancelRecording onClick={cancelRecording}>
                Cancel Recording
              </CancelRecording>
            )}
            {isRecording && (
              <CounterWrap>
                <img src={record} />
                {formatDuration(time)}
              </CounterWrap>
            )}
            {!isRecording ? (
              <StartRecBtn
                onClick={async () => {
                  await startRecording();
                  setIsRecording(true);
                }}
              >
                <img src={Rec} />
                Start Recording
              </StartRecBtn>
            ) : (
              <RecButtonsWrap>
                <PauseResumeBtn
                  onClick={() => {
                    pauseResumeRecording();
                  }}
                >
                  <img src={!isPaused ? pauseIcon : resumeIcon} />
                  {!isPaused ? "Pause" : "Resume"} Recording
                </PauseResumeBtn>
                <StartRecBtn className="stop" onClick={stopRecording}>
                  <img src={stopIcon} />
                  Stop Recording
                </StartRecBtn>
              </RecButtonsWrap>
            )}
          </StartRecBtnWrap>
        </SlidesMainCont>
        <SlideModeSettingsWrap>
          <SlideModeSettingsCont>
            <SettingsItem
              onClick={async () => {
                let cam = await checkCamera();
                if (cam) {
                  setHideCam(
                    hideCam === " " || hideCam === "enable"
                      ? "disable"
                      : "enable"
                  );
                }
              }}
              onMouseEnter={() => {
                setHoverEle("cam");
              }}
              onMouseLeave={() => {
                setHoverEle("");
              }}
            >
              <img
                src={
                  hideCam === "enable" || hideCam === " " ? Camera : CameraHov
                }
              />
              <div className="text">
                {hideCam === "enable" || hideCam === " "
                  ? "Hide Camera"
                  : "Enable Camera"}
              </div>
            </SettingsItem>
            <SettingsItem
              onClick={handleMic}
              onMouseEnter={() => {
                setHoverEle("mic");
              }}
              onMouseLeave={() => {
                setHoverEle("");
              }}
              className={
                mic !== "enable" && mic !== "disable" ? "mic-notavail" : ""
              }
            >
              <img src={mic === "enable" ? Mic : MicHov} />
              <div className="text">
                {mic === "enable" ? "Mic Off" : "Mic On"}
              </div>
            </SettingsItem>
            <div className="dividor" />
            <SettingsItem
              onClick={async () => {
                let cam = await checkCamera();
                if (cam) {
                  setLayoutOpen(!layoutOpen);
                  setColorPick({ ...colorPick, open: false });
                  setPrompt(false);
                }
              }}
              onMouseEnter={() => {
                setHoverEle("layout");
              }}
              onMouseLeave={() => {
                setHoverEle("");
              }}
            >
              <img src={hoverEle === "layout" ? LayoutHov : Layout} />
              <div className="text">Layout</div>
              {layoutOpen && (
                <LayoutMenuWrap>
                  <LayoutMenuHeadWrap>
                    <div>Layout</div>
                    <img
                      src={Close}
                      onClick={(e) => {
                        e.stopPropagation();
                        setLayoutOpen(false);
                      }}
                    />
                  </LayoutMenuHeadWrap>
                  <div className="dividor" />
                  <LayoutItemWrap>
                    <img
                      src={
                        clipShape === " " || clipShape === "circle"
                          ? lay1Ac
                          : lay1
                      }
                      onClick={() => {
                        setClipShape("circle");
                      }}
                    />
                    <img
                      src={clipShape === "rectangle" ? lay2Ac : lay2}
                      onClick={() => {
                        setClipShape("rectangle");
                      }}
                    />
                    {/* <img src={lay3} /> */}
                  </LayoutItemWrap>
                </LayoutMenuWrap>
              )}
            </SettingsItem>{" "}
                        
            <SettingsItem
              onClick={() => {
                setColorPick({ ...colorPick, open: !colorPick?.open });
                setLayoutOpen(false);
                setPrompt(false);
              }}
              onMouseEnter={() => {
                setHoverEle("bg");
              }}
              onMouseLeave={() => {
                setHoverEle("");
              }}
            >
              <img src={hoverEle === "bg" ? BackgroundHov : Background} />
              <div className="text">Background</div>
              {colorPick?.open && (
                <ColorPicker
                  cancelFn={cancelFn}
                  saveFn={saveFn}
                  handleColorChange={handleColorChange}
                  color={colorPick?.picked}
                />
              )}
            </SettingsItem>
            <SettingsItem
              onClick={() => {
                setPrompt(!prompt);
                setLayoutOpen(false);
                setColorPick({ ...colorPick, open: false });
              }}
              onMouseEnter={() => {
                setHoverEle("pro");
              }}
              onMouseLeave={() => {
                setHoverEle("");
              }}
            >
              <img src={hoverEle === "pro" ? PromptHov : Prompt} />
              <div>Prompt</div>
            </SettingsItem>
          </SlideModeSettingsCont>
        </SlideModeSettingsWrap>
      </SlideMainWrap>
      {previewSec && (
        <VidedoPresentationPreview
          setPreviewSec={setPreviewSec}
          setBlobItem={setBlobItem}
          blobItem={blobItem}
          pptName={fileName}
        />
      )}

      {prompt && (
        <PromptBox
          setPrompt={setPrompt}
          setPromptwithSlide={setPromptwithSlide}
          propmptWithSilde={propmptWithSilde}
          total={pdfDocument?.numPages}
          selectedPage={selectedPage}
        />
      )}
    </SlideBoadyWrap>
  );
};

export default SlideBoady;
