import React, { useEffect, useState } from "react";
import { PresentationWrap } from "./presentation-styled";
import UploadPptCompt from "./component/uploadPptCompt";
import PresentationMianSection from "./component/presentationMianSection";
import { DashboardProvider } from "../../context/dashboard/dashAuth";
import IssueBox from "../Dashboard/CaptureDemo/components/IssueBox/issueBox";
import RecordingTips from "./component/recordingTips";
import { usePresentation } from "./context/presentationContext";

const Presentation = () => {
  const [pres, setPres] = useState(false);
  const [imgSrc, setImgSrc] = useState();
  const [imgUrls, setImg] = useState();
  const [fileName, setFileName] = useState("");
  const {presState,presDispatch} = usePresentation()
  const {feedbackPopup,tipsPopup} = presState
  useEffect(() => {
    if (imgUrls?.length > 0) {
      setImgSrc(imgUrls[0]?.url);
      setPres(true);
    }
  }, [imgUrls]);
  return (
    <>
      {!pres && !imgUrls ? (
        <PresentationWrap>
          <UploadPptCompt
            setImg={setImg}
            setPres={setPres}
            setFileName={setFileName}
            
          />
        </PresentationWrap>
      ) : (
        <DashboardProvider>
          <PresentationMianSection
            pdfUrl={imgUrls}
            fileName={fileName}
            setFileName={setFileName}
            
          />
        </DashboardProvider>
      )}
      {(feedbackPopup === "upload" || feedbackPopup === "record") && (
        <IssueBox  />
      )}
      {(tipsPopup === "upload" || tipsPopup === "record") && (
        <RecordingTips  />
      )}
    </>
  );
};

export default Presentation;
