import React, { useRef, useState } from "react";
import {
  FileNameEditWrap,
  RenameComptWrap,
  RenameInnput,
} from "../presentation-styled";
import Rename from "../../Dashboard/CaptureDemoEdit/Images/rename.svg";
const RenameCompt = ({ fileName,setFileName }) => {
  const [edit, setEdit] = useState(false);
  return (
    <RenameComptWrap>
      {!edit ? (
        <FileNameEditWrap
          onClick={() => {
            setEdit(true);
          }}
        >
          {fileName} <img src={Rename} />
        </FileNameEditWrap>
      ) : (
        <RenameInnput type="text" value={fileName} onChange={(event)=>{
            setFileName(event?.target?.value)
        }} onBlur={()=>{
            setEdit(false)
        }} autoFocus onFocus={(event)=>{
            event.target.select(); 
        }}/>
      )}
    </RenameComptWrap>
  );
};

export default RenameCompt;
