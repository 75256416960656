import { createContext, useContext, useReducer } from "react";



const PresentationContext = createContext(null);

const initialState = {
    tipsPopup: "",
    feedbackPopup: "",

};

const PresentationReducer = (state, action) => {
    switch (action.type) {
        case "HANDLE_TIPS_POPUP":
            return {
                ...state,
                tipsPopup: action?.data
            }
        case "HANDLE_FEEDBACK_POPUP":
            return {
                ...state,
                feedbackPopup: action?.data
            }

    }

}

const usePresentationReducer = () => {
    return useReducer(PresentationReducer, initialState);
};

export const PresentationProvider = ({ children }) => {
    const [state, dispatch] = usePresentationReducer();

    return (
        <PresentationContext.Provider
            value={{
                presState: state,
                presDispatch: dispatch,
            }}
        >
            {children}
        </PresentationContext.Provider>
    );

}

export const usePresentation = () => {
    return useContext(PresentationContext);
}