import styled from "styled-components";
import theme from "../../../../../theme";
export const KebabbWrapper = styled.div`
  z-index: 99999999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 33, 38, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const KebabPopup = styled.div`
  z-index: 9999;
  padding:${(props) =>
          props.embed  ? "32px 0px 30px 0px": "26px 0px 0px 0px"};
  width: 613px;
  ${theme.breakpoints.tab} {
  padding: '16px 10px 0px 0px';
  }
  ${theme.breakpoints.tab} {
  width: 70%;
  }
  height: ${(props) =>
          props.embed  ? "342px" : "410px"} !important;
  
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 12.1%;
  display: flex;
  flex-direction: column;
  form{
    width: 100%;
    padding: 0px 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

export const FoundAnyIssue = styled.div`
  color: ${theme.colors.lightText1};
  color: #404b59;
  font-family: "Inter";
  font-size: 20px;
  ${theme.breakpoints.tab} {
  font-size: 16px;
  }
  font-style: normal;
  font-weight: 600;
`
export const CancelImgWrap = styled.div`
  display: flex;
  justify-content: end;
`;
export const CancelImg = styled.img`
  width: 15px !important;
  height: 15px !important;
  cursor: pointer;
`;
export const IssueWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 0px 20px;

margin-bottom: 9px;
`

export const IssueSubHeading = styled.div`
width: 100%;
color: #667085;
text-align: left;
    font-family: "Inter";
    font-size: 14px;
    ${theme.breakpoints.tab} {
        font-size: 12px;

  }
    font-style: normal;
    font-weight: 500;
    display: flex;
    padding: 0px 20px;

`
export const ErrorMsg = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11.8443px;
  line-height: 13px;
  letter-spacing: 0.323026px;
  color: #ff3b30;
  margin: 8px;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: -24px;
  left: 13px;
`;
export const WebsiteUrl = styled.input`
  width: 100%;
  ${theme.breakpoints.tab} {
  width: 100%;
  }
  height: 42px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: #fcfcfd;
  color: ${theme.colors.lightText1};
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;

  &::placeholder {
    color: ${theme.colors.placeHolder};
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &:focus {
    outline: none;
  }
`;

export const DescriptionArea = styled.textarea`
  border-radius: 4px;
border: 1px solid #E6E6E6;
height: 163px;
width: 100%;
background: #FCFCFD;
margin:12px 0px 0px 0px;
color: #667085;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: left;
padding: 13px;
resize: none;
&:focus {
  outline: none;
}
`
export const SendBtn = styled.button`
display: inline-flex;
padding: 2px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: #674EFF;
color: #FFF;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
cursor: pointer;
border: none;
&:hover{
background: #5C41FF;
}
margin-top: 20px;
`