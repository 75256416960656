import React, { useEffect, useState } from 'react';
import * as pdfjs from "pdfjs-dist/webpack";
import SideSlides from './sideSlides';
import SlideBoady from './slideBoady';
import { LoaderWrap, PresentRecordWrap, SlideImgWrap, SlidesHead } from '../presentation-styled';
import Load from '../Images/loadBoy.gif'
import { Recorder } from '../../../components/Sidebar/Sidebar.styled';
import WebLogo from "../../../components/Navbar/images/WebLogo.svg";
import { useNavigate } from 'react-router-dom';
import slideIcon from "../Images/sildes.svg";
import { usePresentation } from '../context/presentationContext';

// Set the workerSrc for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PresentationMianSection = ({ pdfUrl, fileName,setFileName }) => {
  const [pdfDocument, setPdfDocument] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [hidepanel, setHidePanel] = useState(false);
  const {presState,presDispatch} = usePresentation()
  const {feedbackPopup,tipsPopup} = presState
  const navigate = useNavigate();
  useEffect(() => {
    const loadPdf = async () => {
      const loadingTask = pdfjs.getDocument(pdfUrl);
      const doc = await loadingTask.promise;
      setPdfDocument(doc);
    };

    loadPdf();
  }, [pdfUrl]);
  const handleKeyDown = (event) => {
    if (pdfDocument) {
      if (event.key === "ArrowRight") {
        setSelectedPage((prevPage) => {
          return prevPage < pdfDocument.numPages ? prevPage + 1 : prevPage;
        });
      } else if (event.key === "ArrowLeft") {
        setSelectedPage((prevPage) => {
          return prevPage > 1 ? prevPage - 1 : prevPage;
        });
      }
    }
  };

  useEffect(() => {
    const handleKeyDownWrapper = (event) => handleKeyDown(event);

    if (pdfDocument) {
      window.addEventListener("keydown", handleKeyDownWrapper);

      // Cleanup listener on unmount
      return () => {
        window.removeEventListener("keydown", handleKeyDownWrapper);
      };
    }
  }, [pdfDocument]); // Dependency array includes pdfDocument

  return (
    <PresentRecordWrap notSec={pdfDocument}>
      {pdfDocument ? (
        <>
          {/* Render SideSlides only if the panel is not hidden */}
          {!hidepanel && (
            <SideSlides
              pdfUrl={pdfUrl}
              pdfDocument={pdfDocument}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              setHidePanel={setHidePanel}
            />
          )}

          {/* Render SlideBody */}
          <SlideBoady
            hidepanel={hidepanel}
            pdfUrl={pdfUrl}
            pdfDocument={pdfDocument}
            fileName={fileName}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setHidePanel={setHidePanel}
            setFileName={setFileName}
          />
        </>
      ) : (
        /* Loader for when the PDF document is not available */
        <LoaderWrap>
          <div>Wait a sec, We are Loading your Presentation...</div>
          <img src={Load} alt="Loading" draggable="false" />
        </LoaderWrap>
      )}

      {/* Header with toggle button for the side panel */}
     
     
    </PresentRecordWrap>

  );
};

export default PresentationMianSection;