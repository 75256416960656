import React from "react";
import {
  CancelImg,
  CancelImgWrap,
  DescriptionArea,
  ErrorMsg,
  FoundAnyIssue,
  IssueSubHeading,
  IssueWrap,
  KebabPopup,
  KebabbWrapper,
  SendBtn,
  WebsiteUrl,
} from "./issueBox-styled";
import cross from "../../../../Dashboard/Workspace/Images/cross.svg";
import { useForm } from "react-hook-form";
import mixpanel from "mixpanel-browser";
import { useAuth } from "../../../../../context/auth";
import { toast } from "react-hot-toast";
import { usePresentation } from "../../../../Presentation/context/presentationContext";
const IssueBox = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const auth = useAuth();
  const {presState,presDispatch} = usePresentation()
  const {feedbackPopup,tipsPopup} = presState
  const handleIssue = (data) => {
    mixpanel.track("Presentation bug recived", {
      name: auth?.authState?.user?.username,
      email: auth?.authState?.user?.email,
      feedback: {
        title: data?.title,
        description: data?.description ? data?.description : "",
      },
      from:feedbackPopup
    });
    presDispatch({ type: "HANDLE_FEEDBACK_POPUP", data: "" })
    toast.success("Issue submitted successfully...");
  };
  
  return (
    <KebabbWrapper onClick={(e) => e.stopPropagation()}>
      <KebabPopup>
        <IssueWrap>
          <FoundAnyIssue>Provide Your Feedback</FoundAnyIssue>

          <CancelImgWrap>
            <CancelImg
              src={cross}
              onClick={(e) => {
                e.stopPropagation();
                presDispatch({ type: "HANDLE_FEEDBACK_POPUP", data: "" })
              }}
              alt=""
              draggable="false"
            />
          </CancelImgWrap>
        </IssueWrap>
        <IssueSubHeading>
          Have any feedback or noticed an issue? Please share it here, and we
          will address it as soon as possible.
        </IssueSubHeading>
        <form onSubmit={handleSubmit(handleIssue)}>
          {errors.title && <ErrorMsg>{errors.title?.message}</ErrorMsg>}
          <WebsiteUrl
            type="text"
            {...register("title", {
              required: {
                value: true,
                message: "Field cannot be empty!",
              },
            })}
            placeholder="Title"
          />
          <DescriptionArea
            type="text"
            {...register("description")}
            placeholder="Description"
          />
          <SendBtn>Submit</SendBtn>
        </form>
      </KebabPopup>
    </KebabbWrapper>
  );
};

export default IssueBox;
